import {Tokens} from './tokens.d';

export const tokens: Tokens = {
  BORDER_RADIUS_XS: '2px',
  BORDER_RADIUS_SM: '4px',
  BORDER_RADIUS_MD: '8px',
  BORDER_RADIUS_LG: '12px',
  BORDER_RADIUS_XL: '16px',
  BORDER_RADIUS_2XL: '24px',
  BORDER_WIDTH_1: '1px',
  BORDER_WIDTH_2: '2px',
  BORDER_WIDTH_3: '3px',
  BORDER_WIDTH_4: '4px',
  COLOR_CORE_NEON_500: '#E0FE2A',
  COLOR_CORE_NEON_600: '#CAEC01',
  COLOR_CORE_NEON_700: '#B1CE01',
  COLOR_CORE_WHITE: '#FFFFFF',
  COLOR_CORE_GREY_50: '#F0F1F4',
  COLOR_CORE_GREY_100: '#E7E9ED',
  COLOR_CORE_GREY_200: '#C6C9D1',
  COLOR_CORE_GREY_300: '#ADB0BC',
  COLOR_CORE_GREY_400: '#6E7189',
  COLOR_CORE_GREY_500: '#52526E',
  COLOR_CORE_GREY_600: '#3D3C56',
  COLOR_CORE_GREY_700: '#2D2B41',
  COLOR_CORE_GREY_800: '#211F30',
  COLOR_CORE_BLACK: '#12101A',
  COLOR_CORE_TRUE_BLACK: '#000000',
  COLOR_CORE_PINK_100: '#FEE3F7',
  COLOR_CORE_PINK_200: '#FDC7EF',
  COLOR_CORE_PINK_300: '#FDAAE7',
  COLOR_CORE_PINK_400: '#FC8EDF',
  COLOR_CORE_PINK_500: '#FB72D7',
  COLOR_CORE_PINK_600: '#ED36BE',
  COLOR_CORE_PINK_700: '#D5069F',
  COLOR_CORE_PINK_800: '#8E046A',
  COLOR_CORE_RED_100: '#FFDCDC',
  COLOR_CORE_RED_200: '#FF9797',
  COLOR_CORE_RED_300: '#FF7575',
  COLOR_CORE_RED_400: '#FF5252',
  COLOR_CORE_RED_500: '#CA0000',
  COLOR_CORE_RED_600: '#A20000',
  COLOR_CORE_RED_700: '#790000',
  COLOR_CORE_RED_800: '#510000',
  COLOR_CORE_SCARLET_100: '#F7DBD1',
  COLOR_CORE_SCARLET_200: '#EFB8A3',
  COLOR_CORE_SCARLET_300: '#E89475',
  COLOR_CORE_SCARLET_400: '#E07047',
  COLOR_CORE_SCARLET_500: '#CE5123',
  COLOR_CORE_SCARLET_600: '#7C3015',
  COLOR_CORE_SCARLET_700: '#7C3015',
  COLOR_CORE_SCARLET_800: '#53200E',
  COLOR_CORE_ORANGE_100: '#FFEBD1',
  COLOR_CORE_ORANGE_200: '#FFDCB0',
  COLOR_CORE_ORANGE_300: '#FFC479',
  COLOR_CORE_ORANGE_400: '#FFA532',
  COLOR_CORE_ORANGE_500: '#FF9815',
  COLOR_CORE_ORANGE_600: '#EB6415',
  COLOR_CORE_ORANGE_700: '#D2511A',
  COLOR_CORE_ORANGE_800: '#B44616',
  COLOR_CORE_CACTUS_100: '#F1F1D5',
  COLOR_CORE_CACTUS_200: '#E2E4AA',
  COLOR_CORE_CACTUS_300: '#D4D680',
  COLOR_CORE_CACTUS_400: '#C5C956',
  COLOR_CORE_CACTUS_500: '#ABAF38',
  COLOR_CORE_CACTUS_600: '#898C2D',
  COLOR_CORE_CACTUS_700: '#676922',
  COLOR_CORE_CACTUS_800: '#444616',
  COLOR_CORE_GREEN_100: '#D1F2E2',
  COLOR_CORE_GREEN_200: '#A3E5C5',
  COLOR_CORE_GREEN_300: '#75D8A8',
  COLOR_CORE_GREEN_400: '#47CB8B',
  COLOR_CORE_GREEN_500: '#2FA86D',
  COLOR_CORE_GREEN_600: '#268657',
  COLOR_CORE_GREEN_700: '#1C6541',
  COLOR_CORE_GREEN_800: '#13432C',
  COLOR_CORE_INDIGO_100: '#E9EAFF',
  COLOR_CORE_INDIGO_200: '#DDDEFF',
  COLOR_CORE_INDIGO_300: '#C6C8FF',
  COLOR_CORE_INDIGO_400: '#B6BAFF',
  COLOR_CORE_INDIGO_500: '#A7ABFF',
  COLOR_CORE_INDIGO_600: '#787EFF',
  COLOR_CORE_INDIGO_700: '#4951FF',
  COLOR_CORE_INDIGO_800: '#00068D',
  ELEVATION_LEVEL1: '0px 0px 4px 0px rgba(0, 0, 0, 0.7)',
  ELEVATION_LEVEL2:
    '0px 2px 12px 0px rgba(0, 0, 0, 0.08), 0px 0px 4px 0px rgba(0, 0, 0, 0.06)',
  ELEVATION_LEVEL3: '0px 4px 16px 0px rgba(0, 0, 0, 0.24)',
  ELEVATION_LEVEL4: '0px 4px 24px 0px rgba(0, 0, 0, 0.32)',
  ELEVATION_LEVEL5: '0px 4px 40px 0px rgba(0, 0, 0, 0.12)',
  FONT_LETTER_SPACING_2XS: '-1.5px',
  FONT_LETTER_SPACING_XS: '-1px',
  FONT_LETTER_SPACING_SM: '-0.25px',
  FONT_LETTER_SPACING_MD: '0px',
  FONT_LETTER_SPACING_LG: '0.5px',
  FONT_LINE_HEIGHT_25: '16px',
  FONT_LINE_HEIGHT_50: '20px',
  FONT_LINE_HEIGHT_100: '24px',
  FONT_LINE_HEIGHT_200: '28px',
  FONT_LINE_HEIGHT_300: '24px',
  FONT_LINE_HEIGHT_400: '32px',
  FONT_LINE_HEIGHT_500: '36px',
  FONT_LINE_HEIGHT_600: '40px',
  FONT_LINE_HEIGHT_700: '40px',
  FONT_LINE_HEIGHT_800: '44px',
  FONT_LINE_HEIGHT_900: '56px',
  FONT_LINE_HEIGHT_1000: '72px',
  FONT_LINE_HEIGHT_1100: '80px',
  FONT_LINE_HEIGHT_YOLO: '1px',
  FONT_SIZE_25: '12px',
  FONT_SIZE_50: '14px',
  FONT_SIZE_100: '16px',
  FONT_SIZE_200: '18px',
  FONT_SIZE_300: '20px',
  FONT_SIZE_400: '24px',
  FONT_SIZE_500: '28px',
  FONT_SIZE_600: '32px',
  FONT_SIZE_700: '36px',
  FONT_SIZE_800: '40px',
  FONT_SIZE_900: '48px',
  FONT_SIZE_1000: '64px',
  FONT_SIZE_1100: '80px',
  FONT_TYPEFACE_PRIMARY: 'DM Sans',
  FONT_WEIGHT_REGULAR: '400',
  FONT_WEIGHT_MEDIUM: '500',
  FONT_WEIGHT_BOLD: '700',
  OPACITY_50: '0.5',
  OPACITY_80: '0.8',
  OPACITY_90: '0.9',
  SPACING_2XS: '4px',
  SPACING_XS: '8px',
  SPACING_SM: '12px',
  SPACING_MD: '16px',
  SPACING_LG: '24px',
  SPACING_XL: '32px',
  SPACING_2XL: '40px',
  SPACING_3XL: '48px',
  SPACING_4XL: '56px',
  SPACING_5XL: '64px',
  TYPOGRAPHY_DISPLAY_LG_MOBILE_TYPEFACE: 'DM Sans',
  TYPOGRAPHY_DISPLAY_LG_MOBILE_FONT_WEIGHT: '700',
  TYPOGRAPHY_DISPLAY_LG_MOBILE_LETTER_SPACING: '-1px',
  TYPOGRAPHY_DISPLAY_LG_MOBILE_SIZE: '36px',
  TYPOGRAPHY_DISPLAY_LG_MOBILE_LINE_HEIGHT: '40px',
  TYPOGRAPHY_DISPLAY_LG_DESKTOP_LETTER_SPACING: '-1.5px',
  TYPOGRAPHY_DISPLAY_LG_DESKTOP_SIZE: '64px',
  TYPOGRAPHY_DISPLAY_LG_DESKTOP_LINE_HEIGHT: '72px',
  TYPOGRAPHY_DISPLAY_MD_MOBILE_TYPEFACE: 'DM Sans',
  TYPOGRAPHY_DISPLAY_MD_MOBILE_FONT_WEIGHT: '700',
  TYPOGRAPHY_DISPLAY_MD_MOBILE_LETTER_SPACING: '-1px',
  TYPOGRAPHY_DISPLAY_MD_MOBILE_SIZE: '36px',
  TYPOGRAPHY_DISPLAY_MD_MOBILE_LINE_HEIGHT: '40px',
  TYPOGRAPHY_DISPLAY_MD_DESKTOP_SIZE: '48px',
  TYPOGRAPHY_DISPLAY_MD_DESKTOP_LINE_HEIGHT: '56px',
  TYPOGRAPHY_DISPLAY_MD_DESKTOP_LETTER_SPACING: '-1.5px',
  TYPOGRAPHY_HEADING_LG_MOBILE_TYPEFACE: 'DM Sans',
  TYPOGRAPHY_HEADING_LG_MOBILE_FONT_WEIGHT: '700',
  TYPOGRAPHY_HEADING_LG_MOBILE_LETTER_SPACING: '-0.25px',
  TYPOGRAPHY_HEADING_LG_MOBILE_SIZE: '28px',
  TYPOGRAPHY_HEADING_LG_MOBILE_LINE_HEIGHT: '40px',
  TYPOGRAPHY_HEADING_LG_DESKTOP_SIZE: '32px',
  TYPOGRAPHY_HEADING_LG_DESKTOP_LINE_HEIGHT: '40px',
  TYPOGRAPHY_HEADING_LG_DESKTOP_LETTER_SPACING: '-0.25px',
  TYPOGRAPHY_HEADING_MD_MOBILE_TYPEFACE: 'DM Sans',
  TYPOGRAPHY_HEADING_MD_MOBILE_FONT_WEIGHT: '700',
  TYPOGRAPHY_HEADING_MD_MOBILE_LETTER_SPACING: '-0.25px',
  TYPOGRAPHY_HEADING_MD_MOBILE_SIZE: '24px',
  TYPOGRAPHY_HEADING_MD_MOBILE_LINE_HEIGHT: '32px',
  TYPOGRAPHY_HEADING_MD_DESKTOP_TYPEFACE: 'DM Sans',
  TYPOGRAPHY_HEADING_MD_DESKTOP_FONT_WEIGHT: '700',
  TYPOGRAPHY_HEADING_MD_DESKTOP_LETTER_SPACING: '-0.25px',
  TYPOGRAPHY_HEADING_MD_DESKTOP_SIZE: '24px',
  TYPOGRAPHY_HEADING_MD_DESKTOP_LINE_HEIGHT: '32px',
  TYPOGRAPHY_HEADING_SM_MOBILE_TYPEFACE: 'DM Sans',
  TYPOGRAPHY_HEADING_SM_MOBILE_FONT_WEIGHT: '500',
  TYPOGRAPHY_HEADING_SM_MOBILE_LETTER_SPACING: '0px',
  TYPOGRAPHY_HEADING_SM_MOBILE_SIZE: '20px',
  TYPOGRAPHY_HEADING_SM_MOBILE_LINE_HEIGHT: '24px',
  TYPOGRAPHY_HEADING_SM_DESKTOP_TYPEFACE: 'DM Sans',
  TYPOGRAPHY_HEADING_SM_DESKTOP_FONT_WEIGHT: '500',
  TYPOGRAPHY_HEADING_SM_DESKTOP_LETTER_SPACING: '0px',
  TYPOGRAPHY_HEADING_SM_DESKTOP_SIZE: '20px',
  TYPOGRAPHY_HEADING_SM_DESKTOP_LINE_HEIGHT: '24px',
  TYPOGRAPHY_HEADING_XS_MOBILE_TYPEFACE: 'DM Sans',
  TYPOGRAPHY_HEADING_XS_MOBILE_FONT_WEIGHT: '700',
  TYPOGRAPHY_HEADING_XS_MOBILE_LETTER_SPACING: '0px',
  TYPOGRAPHY_HEADING_XS_MOBILE_SIZE: '16px',
  TYPOGRAPHY_HEADING_XS_MOBILE_LINE_HEIGHT: '24px',
  TYPOGRAPHY_HEADING_XS_DESKTOP_TYPEFACE: 'DM Sans',
  TYPOGRAPHY_HEADING_XS_DESKTOP_FONT_WEIGHT: '700',
  TYPOGRAPHY_HEADING_XS_DESKTOP_LETTER_SPACING: '0px',
  TYPOGRAPHY_HEADING_XS_DESKTOP_SIZE: '16px',
  TYPOGRAPHY_HEADING_XS_DESKTOP_LINE_HEIGHT: '24px',
  TYPOGRAPHY_PARAGRAPH_LG_MOBILE_TYPEFACE: 'DM Sans',
  TYPOGRAPHY_PARAGRAPH_LG_MOBILE_FONT_WEIGHT: '400',
  TYPOGRAPHY_PARAGRAPH_LG_MOBILE_LETTER_SPACING: '0px',
  TYPOGRAPHY_PARAGRAPH_LG_MOBILE_SIZE: '18px',
  TYPOGRAPHY_PARAGRAPH_LG_MOBILE_LINE_HEIGHT: '28px',
  TYPOGRAPHY_PARAGRAPH_LG_DESKTOP_TYPEFACE: 'DM Sans',
  TYPOGRAPHY_PARAGRAPH_LG_DESKTOP_FONT_WEIGHT: '400',
  TYPOGRAPHY_PARAGRAPH_LG_DESKTOP_LETTER_SPACING: '0px',
  TYPOGRAPHY_PARAGRAPH_LG_DESKTOP_SIZE: '18px',
  TYPOGRAPHY_PARAGRAPH_LG_DESKTOP_LINE_HEIGHT: '28px',
  TYPOGRAPHY_PARAGRAPH_MD_MOBILE_TYPEFACE: 'DM Sans',
  TYPOGRAPHY_PARAGRAPH_MD_MOBILE_FONT_WEIGHT: '400',
  TYPOGRAPHY_PARAGRAPH_MD_MOBILE_LETTER_SPACING: '0px',
  TYPOGRAPHY_PARAGRAPH_MD_MOBILE_SIZE: '16px',
  TYPOGRAPHY_PARAGRAPH_MD_MOBILE_LINE_HEIGHT: '24px',
  TYPOGRAPHY_PARAGRAPH_MD_DESKTOP_TYPEFACE: 'DM Sans',
  TYPOGRAPHY_PARAGRAPH_MD_DESKTOP_FONT_WEIGHT: '400',
  TYPOGRAPHY_PARAGRAPH_MD_DESKTOP_LETTER_SPACING: '0px',
  TYPOGRAPHY_PARAGRAPH_MD_DESKTOP_SIZE: '16px',
  TYPOGRAPHY_PARAGRAPH_MD_DESKTOP_LINE_HEIGHT: '24px',
  TYPOGRAPHY_PARAGRAPH_SM_MOBILE_TYPEFACE: 'DM Sans',
  TYPOGRAPHY_PARAGRAPH_SM_MOBILE_FONT_WEIGHT: '400',
  TYPOGRAPHY_PARAGRAPH_SM_MOBILE_LETTER_SPACING: '0px',
  TYPOGRAPHY_PARAGRAPH_SM_MOBILE_SIZE: '14px',
  TYPOGRAPHY_PARAGRAPH_SM_MOBILE_LINE_HEIGHT: '20px',
  TYPOGRAPHY_PARAGRAPH_SM_DESKTOP_TYPEFACE: 'DM Sans',
  TYPOGRAPHY_PARAGRAPH_SM_DESKTOP_FONT_WEIGHT: '400',
  TYPOGRAPHY_PARAGRAPH_SM_DESKTOP_LETTER_SPACING: '0px',
  TYPOGRAPHY_PARAGRAPH_SM_DESKTOP_SIZE: '14px',
  TYPOGRAPHY_PARAGRAPH_SM_DESKTOP_LINE_HEIGHT: '20px',
  TYPOGRAPHY_LINK_LG_MOBILE_TYPEFACE: 'DM Sans',
  TYPOGRAPHY_LINK_LG_MOBILE_FONT_WEIGHT: '700',
  TYPOGRAPHY_LINK_LG_MOBILE_LETTER_SPACING: '0px',
  TYPOGRAPHY_LINK_LG_MOBILE_SIZE: '18px',
  TYPOGRAPHY_LINK_LG_MOBILE_LINE_HEIGHT: '28px',
  TYPOGRAPHY_LINK_LG_MOBILE_TEXT_DECORATION: 'underline',
  TYPOGRAPHY_LINK_LG_DESKTOP_TYPEFACE: 'DM Sans',
  TYPOGRAPHY_LINK_LG_DESKTOP_FONT_WEIGHT: '700',
  TYPOGRAPHY_LINK_LG_DESKTOP_LETTER_SPACING: '0px',
  TYPOGRAPHY_LINK_LG_DESKTOP_SIZE: '18px',
  TYPOGRAPHY_LINK_LG_DESKTOP_LINE_HEIGHT: '28px',
  TYPOGRAPHY_LINK_LG_DESKTOP_TEXT_DECORATION: 'underline',
  TYPOGRAPHY_LINK_MOBILE_TYPEFACE: 'DM Sans',
  TYPOGRAPHY_LINK_MOBILE_FONT_WEIGHT: '700',
  TYPOGRAPHY_LINK_MOBILE_LETTER_SPACING: '0px',
  TYPOGRAPHY_LINK_MOBILE_SIZE: '16px',
  TYPOGRAPHY_LINK_MOBILE_LINE_HEIGHT: '24px',
  TYPOGRAPHY_LINK_MOBILE_TEXT_DECORATION: 'underline',
  TYPOGRAPHY_LINK_DESKTOP_TYPEFACE: 'DM Sans',
  TYPOGRAPHY_LINK_DESKTOP_FONT_WEIGHT: '700',
  TYPOGRAPHY_LINK_DESKTOP_LETTER_SPACING: '0px',
  TYPOGRAPHY_LINK_DESKTOP_SIZE: '16px',
  TYPOGRAPHY_LINK_DESKTOP_LINE_HEIGHT: '24px',
  TYPOGRAPHY_LINK_DESKTOP_TEXT_DECORATION: 'underline',
  TYPOGRAPHY_LINK_SM_MOBILE_TYPEFACE: 'DM Sans',
  TYPOGRAPHY_LINK_SM_MOBILE_FONT_WEIGHT: '700',
  TYPOGRAPHY_LINK_SM_MOBILE_LETTER_SPACING: '0px',
  TYPOGRAPHY_LINK_SM_MOBILE_SIZE: '14px',
  TYPOGRAPHY_LINK_SM_MOBILE_LINE_HEIGHT: '20px',
  TYPOGRAPHY_LINK_SM_MOBILE_TEXT_DECORATION: 'underline',
  TYPOGRAPHY_LINK_SM_DESKTOP_TYPEFACE: 'DM Sans',
  TYPOGRAPHY_LINK_SM_DESKTOP_FONT_WEIGHT: '700',
  TYPOGRAPHY_LINK_SM_DESKTOP_LETTER_SPACING: '0px',
  TYPOGRAPHY_LINK_SM_DESKTOP_SIZE: '14px',
  TYPOGRAPHY_LINK_SM_DESKTOP_LINE_HEIGHT: '20px',
  TYPOGRAPHY_LINK_SM_DESKTOP_TEXT_DECORATION: 'underline',
  TYPOGRAPHY_LINK_XS_DESKTOP_TYPEFACE: 'DM Sans',
  TYPOGRAPHY_LINK_XS_DESKTOP_FONT_WEIGHT: '700',
  TYPOGRAPHY_LINK_XS_DESKTOP_LETTER_SPACING: '0px',
  TYPOGRAPHY_LINK_XS_DESKTOP_SIZE: '12px',
  TYPOGRAPHY_LINK_XS_DESKTOP_LINE_HEIGHT: '16px',
  TYPOGRAPHY_LINK_XS_DESKTOP_TEXT_DECORATION: 'underline',
  TYPOGRAPHY_LINK_XS_MOBILE_TYPEFACE: 'DM Sans',
  TYPOGRAPHY_LINK_XS_MOBILE_FONT_WEIGHT: '700',
  TYPOGRAPHY_LINK_XS_MOBILE_LETTER_SPACING: '0px',
  TYPOGRAPHY_LINK_XS_MOBILE_SIZE: '12px',
  TYPOGRAPHY_LINK_XS_MOBILE_LINE_HEIGHT: '16px',
  TYPOGRAPHY_LINK_XS_MOBILE_TEXT_DECORATION: 'underline',
  TYPOGRAPHY_BUTTON_LABEL_MOBILE_TYPEFACE: 'DM Sans',
  TYPOGRAPHY_BUTTON_LABEL_MOBILE_FONT_WEIGHT: '500',
  TYPOGRAPHY_BUTTON_LABEL_MOBILE_LETTER_SPACING: '0px',
  TYPOGRAPHY_BUTTON_LABEL_MOBILE_SIZE: '16px',
  TYPOGRAPHY_BUTTON_LABEL_MOBILE_LINE_HEIGHT: '24px',
  TYPOGRAPHY_BUTTON_LABEL_DESKTOP_TYPEFACE: 'DM Sans',
  TYPOGRAPHY_BUTTON_LABEL_DESKTOP_FONT_WEIGHT: '500',
  TYPOGRAPHY_BUTTON_LABEL_DESKTOP_LETTER_SPACING: '0px',
  TYPOGRAPHY_BUTTON_LABEL_DESKTOP_SIZE: '16px',
  TYPOGRAPHY_BUTTON_LABEL_DESKTOP_LINE_HEIGHT: '24px',
  TYPOGRAPHY_BUTTON_LABEL_SM_MOBILE_TYPEFACE: 'DM Sans',
  TYPOGRAPHY_BUTTON_LABEL_SM_MOBILE_FONT_WEIGHT: '500',
  TYPOGRAPHY_BUTTON_LABEL_SM_MOBILE_LETTER_SPACING: '0px',
  TYPOGRAPHY_BUTTON_LABEL_SM_MOBILE_SIZE: '14px',
  TYPOGRAPHY_BUTTON_LABEL_SM_MOBILE_LINE_HEIGHT: '20px',
  TYPOGRAPHY_BUTTON_LABEL_SM_DESKTOP_TYPEFACE: 'DM Sans',
  TYPOGRAPHY_BUTTON_LABEL_SM_DESKTOP_FONT_WEIGHT: '500',
  TYPOGRAPHY_BUTTON_LABEL_SM_DESKTOP_LETTER_SPACING: '0px',
  TYPOGRAPHY_BUTTON_LABEL_SM_DESKTOP_SIZE: '14px',
  TYPOGRAPHY_BUTTON_LABEL_SM_DESKTOP_LINE_HEIGHT: '20px',
  TYPOGRAPHY_EYEBROW_MOBILE_TYPEFACE: 'DM Sans',
  TYPOGRAPHY_EYEBROW_MOBILE_FONT_WEIGHT: '500',
  TYPOGRAPHY_EYEBROW_MOBILE_LETTER_SPACING: '0.5px',
  TYPOGRAPHY_EYEBROW_MOBILE_SIZE: '14px',
  TYPOGRAPHY_EYEBROW_MOBILE_LINE_HEIGHT: '1px',
  TYPOGRAPHY_EYEBROW_MOBILE_TEXT_TRANSFORM: 'uppercase',
  TYPOGRAPHY_EYEBROW_DESKTOP_TYPEFACE: 'DM Sans',
  TYPOGRAPHY_EYEBROW_DESKTOP_FONT_WEIGHT: '500',
  TYPOGRAPHY_EYEBROW_DESKTOP_LETTER_SPACING: '0.5px',
  TYPOGRAPHY_EYEBROW_DESKTOP_SIZE: '14px',
  TYPOGRAPHY_EYEBROW_DESKTOP_LINE_HEIGHT: '1px',
  TYPOGRAPHY_EYEBROW_DESKTOP_TEXT_TRANSFORM: 'uppercase',
};
